import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>At Netzfolio, we offer a diverse range of cutting-edge services designed to fuel your business's digital evolution. Our comprehensive suite of solutions spans from custom software development to cloud application deployment, ensuring that your enterprise remains agile, efficient, and ahead of the curve in today's dynamic market.
              </p>
            </div>
          </div>
          <div className="row g-4">
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={1} image="/img/icons/software-development.png" title="Custom Software Development" details="Tailored to fit your unique business requirements, our custom software development services deliver scalable, intuitive solutions crafted to optimize your operations and drive innovation."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={2} image="/img/icons/erp.png" title="Enterprise Resource Planning (ERP)" details="Streamline your business processes and enhance decision-making with our robust ERP solution. That is designed to integrate and manage your core business functions seamlessly."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={3}  image="/img/icons/crm.png" title="Customer Relationship Management (CRM)" details="Build relationships with your customers through personalized interactions and targeted marketing campaigns with our CRM solutions, empowering you to deliver exceptional customer experiences."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={4} image="/img/icons/hrm.png" title="Human Resource Management (HRM)" details="Simplify HR processes, automate workflows, and empower your workforce with our comprehensive HRM solutions, enabling you to attract, retain, and develop top talent while fostering a culture of growth and engagement."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={5} image="/img/icons/lms.png" title="Learning Management System (LMS)" details="Empower your organization with our customizable learning management system, enabling you to deliver interactive training, track progress, and drive employee development while fostering a culture of continuous learning."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={6} image="/img/icons/web-design.png" title="Website Development and Design" details="Make a lasting impression online with our bespoke website development and design services, combining creativity, functionality, and user experience to create engaging digital experiences that resonate with your audience."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={7} image="/img/icons/pos.png" title="Point of Sale (POS) Solutions" details="Elevate your retail experience and drive sales with our intuitive POS solutions, equipped with advanced features for inventory management, sales tracking, and customer engagement, all designed to optimize your retail operations."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={8} image="/img/icons/accounting.png" title="Accounting Software" details="Gain real-time visibility into your financial health and streamline your accounting processes with our user-friendly accounting software, ensuring accuracy, compliance, and informed decision-making."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={9} image="/img/icons/ecommerce.png" title="E-commerce Solutions" details="Expand your online presence growth with our tailored e-commerce solutions, equipped with powerful features for online storefronts, secure payment processing, and seamless integration with your existing systems."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={10} image="/img/icons/cloud-application.png" title="Cloud App Development" details="Embrace the flexibility and scalability of cloud technology with our cloud application development services, enabling you to build, deploy, and manage scalable applications that adapt to your evolving business needs."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={11} image="/img/icons/consulting-it.png" title="Consulting and IT Strategy" details="Leverage our expertise and industry insights to develop strategic IT roadmaps and initiatives that align with your business goals, drive innovation, and maximize the value of your technology investments."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={12} image="/img/icons/support.png" title="Maintenance and Support" details="Ensure the seamless operation of your systems with our reliable maintenance and support services, offering proactive monitoring, timely updates, and responsive assistance to keep your business running smoothly."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={13} image="/img/icons/automation.png" title="Robotic Process Automation" details="Revolutionize your business operations with Robotic Process Automation (RPA) solutions from Netzfolio. Our RPA services harness the power of automation to streamline repetitive tasks, reduce errors, and boost productivity across your organization. Experience the transformative potential of RPA with Netzfolio as your trusted automation partner."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={14} image="/img/icons/uiux.png" title="UI/UX, Graphic Designing" details="Embark on a journey of design excellence with our unrivaled Graphic and UI/UX services. Seamlessly blending creativity and professionalism, we sculpt captivating digital experiences that leave lasting impressions. Trust us to sculpt your brand narrative into visually stunning masterpieces that resonate deeply with your audience."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={15} image="/img/icons/seo.png" title="Search Engine Optimization (SEO)" details="Elevate your online visibility and drive organic traffic to your website with Netzfolio's SEO services. Our dedicated team of SEO experts utilizes proven strategies and cutting-edge techniques to optimize your website for search engines, ensuring that your business stands out in a crowded digital landscape. "/>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
