import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function ClientTestimonial() {
    const testimonial={
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <div className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>Client Say About Us</h2>
              <p>
              Explore testimonials and success stories that highlight our dedication to exceeding expectations, building lasting partnerships, and driving tangible results for businesses like yours. Hear firsthand how Netzfolio has helped businesses like yours achieve their goals and unlock their full potential in the digital age.
              </p>
            </div>
          </div>
          <Swiper {...testimonial} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Daniel Brown</h5>
                  <span>CEO</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  Netzfolio's customer service is outstanding! From the initial consultation to ongoing support, their team, led by Andrew Johnson, Customer Success Manager, has been responsive, knowledgeable, and genuinely invested in our satisfaction. It's rare to find such dedication in a service provider. Highly recommend!
                  </p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL +"/img/reivewer.jpg"} alt="" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Alex Patel</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  Choosing Netzfolio for our e-commerce solution was one of the best decisions we made for our business. Their team, particularly Rachel Adams, E-commerce Specialist, guided us through every step of the process, from platform selection to design customization. The result? A sleek, user-friendly online store that has significantly boosted our sales. Thank you, Netzfolio!
                  </p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jessica Wong</h5>
                  <span>Founder</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  Netzfolio's consulting services provided invaluable strategic guidance for our IT initiatives. Their team, led by Thomas Roberts, IT Consultant, conducted a thorough assessment of our technology infrastructure and proposed actionable recommendations that aligned perfectly with our business objectives. It's rare to find consultants who are both knowledgeable and pragmatic.
                  </p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL +"/img/reivewer.jpg"} alt="" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Michelle Garcia</h5>
                  <span>HR Director</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                  We engaged Netzfolio for our HRM software implementation, and we couldn't be happier with the results. Their team, led by Sarah Thompson, HRM Specialist, took the time to understand our unique needs and tailored the solution to fit seamlessly into our existing processes. The implementation was smooth, and the ongoing support has been exceptional.
                  </p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="" /> */}
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ClientTestimonial;
