import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src={process.env.PUBLIC_URL + "/img/why1.png"} alt="why-choose images" />
                </div>
                <div className="choose-banner2">
                  <img src={process.env.PUBLIC_URL + "/img/why2.png"} alt="why-choose images" />
                  <img src={process.env.PUBLIC_URL + "/img/why3.png"} alt="why-choose images" />
                </div>
                <div className="years">
                  <h5>20+</h5>
                  <span>Years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>Navigating Your Digital Journey with Precision and Purpose</h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">150</span><sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">250</span><sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">50</span><sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>At Netzfolio, we offer compelling reasons to entrust us with your projects. Our team combines extensive industry experience with a forward-thinking approach, ensuring that we not only meet but exceed your expectations.We prioritize client satisfaction above all else, tailoring our services to your unique needs and objectives. From customized solutions to transparent communication and ongoing support, we're dedicated to delivering results that propel your business forward.</p>
                <div className="buttons-group">
                  <span>24/7 Support</span>
                  <span>Unique Design</span>
                  <span>Clean Code Develope</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default WhyChooseUs