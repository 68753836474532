import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  
  Autoplay,
  Navigation,
  EffectFade,
  Pagination
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade,Pagination,]);
function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay:{
          delay: 3500,
          isableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>Client Say About Us</h2>
              <p>Discover what our clients have to say about their experiences partnering with NetzFolio. From small businesses to large enterprises, our commitment to delivering exceptional service and innovative solutions has earned us praise and loyalty from clients across industries.</p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Daniel Brown</h5>
                  <span>CEO</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Netzfolio's customer service is outstanding! From the initial consultation to ongoing support, their team, led by Andrew Johnson, Customer Success Manager, has been responsive, knowledgeable, and genuinely invested in our satisfaction. It's rare to find such dedication in a service provider. Highly recommend!</p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Alex Patel</h5>
                  <span>Director</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Choosing Netzfolio for our e-commerce solution was one of the best decisions we made for our business. Their team, particularly Rachel Adams, E-commerce Specialist, guided us through every step of the process, from platform selection to design customization. The result? A sleek, user-friendly online store that has significantly boosted our sales. Thank you, Netzfolio!</p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="testimonial-img" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Jessica Wong</h5>
                  <span>Founder</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Netzfolio's consulting services provided invaluable strategic guidance for our IT initiatives. Their team, led by Thomas Roberts, IT Consultant, conducted a thorough assessment of our technology infrastructure and proposed actionable recommendations that aligned perfectly with our business objectives. It's rare to find consultants who are both knowledgeable and pragmatic.</p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Michelle Garcia</h5>
                  <span>HR Director</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>We engaged Netzfolio for our HRM software implementation, and we couldn't be happier with the results. Their team, led by Sarah Thompson, HRM Specialist, took the time to understand our unique needs and tailored the solution to fit seamlessly into our existing processes. The implementation was smooth, and the ongoing support has been exceptional.</p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + '/img/reivewer-1.jpg'} alt="testimonial-img" /> */}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Mark Davis</h5>
                  <span>CTO</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>We partnered with Netzfolio for our custom software development needs, and they exceeded our expectations at every turn. From concept to execution, their team, led by David Wilson, Project Manager, demonstrated professionalism, expertise, and a genuine commitment to our success. We look forward to future collaborations!</p>
                  <div className="reviewer">
                    {/* <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" /> */}
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>  
    </>
  )
}

export default Testimonial