import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider= {
    loop: true,
    speed: 1000,
    autoplay:{
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        576: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        }
    }
  }
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
              At NetzFolio, we offer a diverse range of cutting-edge services designed to fuel your business's digital evolution.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/software-development.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Software Development</h4>
                  <p>
                  Tailored to fit your unique business requirements, our custom software development services deliver scalable, intuitive solutions crafted to optimize your operations and drive innovation.
                  </p>
                  
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/support.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Support & Maintenance</h4>
                  <p>
                  Ensure the seamless operation of your systems with our reliable maintenance and support services, offering proactive monitoring, timely updates, and responsive assistance to keep your business running smoothly.
                  </p>
                  
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/consulting-it.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Consulting & IT Strategy</h4>
                  <p>
                  Leverage our expertise and industry insights to develop strategic IT roadmaps and initiatives that align with your business goals, drive innovation, and maximize the value of your technology investments.
                  </p>
                  
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
