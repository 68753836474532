import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap2() {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Case Study</span>
              <h2>Project</h2>
              <p>
              Welcome to our Projects page, where innovation meets implementation. Explore a showcase of our diverse portfolio, ranging from bespoke software solutions to comprehensive ERP integrations and everything in between. Each project represents a unique collaboration between our team of experts and visionary clients, resulting in transformative digital solutions that drive business growth and success. 
              </p>
            </div>
          </div>
          <ProjectCardFilter/>
        </div>
      </div>
    </>
  );
}

export default ProjectWrap2;
