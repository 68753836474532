import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Case Study</span>
              <h2>Project</h2>
              <p>
              Dive into our portfolio to witness firsthand how we turn ideas into reality, leveraging cutting-edge technologies and industry best practices to deliver exceptional results for every project, every time.
              </p>
            </div>
          </div>
          <ProjectCardFilter/>
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
